<template>
<v-card>
  <div class="background">
  <v-layout class="bg-background" style="overflow-y: scroll; height: 100%">
    <v-app-bar
      color="primary"
      prominent
      extended
      :extension-height="(!drawer && !!qu.id && (qMenu == 1)) ? (quMode ? (quInfo ? 410 : 200) : 76) : 0"      
    >
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
        <v-badge v-if="config.queues?.length" :content="config.queues.length" color="red-darken-3">
          <q-icon icon="menu"/>
        </v-badge>
        <q-icon v-else icon="menu"/>
      </v-app-bar-nav-icon>
      <v-toolbar-title id="qu-title" class="text-end me-4" :title="JSON.stringify(config)">{{$t('message.main.eQueue')}}</v-toolbar-title>
      <template v-if="!!qu.id && (qMenu == 1)" v-slot:extension>
        <div class="h-100 w-100 bg-background">
          <v-container style="max-width: 600px" class="py-0">
            <v-row class="pt-3">
              <v-col cols="2" class="my-0 py-0 text-start">
                <v-switch 
                  hide-details
                  color="success"
                  class="mt-n2 py-0 q-switch-label" 
                  v-model="quMode" 
                >
                  <template v-slot:label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="10" class="my-0 py-0 text-end">
                <h2 class="mt-1 mb-0 text-warning">
                  {{qu.title}}
                </h2>
              </v-col>
            </v-row>
            <v-divider class="my-1"/>
            <v-row class="my-0 py-0">
              <v-col cols="12" class="my-0 py-0">
                <h3 class="my-0 text-primary">
                  {{$t(quMode ? 'message.main.queueManage' : 'message.main.tabInfo')}}
                </h3>
              </v-col>
            </v-row>
            <template v-if="quMode">
            <v-divider class="my-1"/>
            <v-row class="mt-1" justify="center">
              <v-col cols="4" v-for="(item, key) in statement" :key="1+key">
                <v-btn 
                  variant="elevated"
                  :disabled="!item && !qu.config.auto_busy"
                  :color="(qu.statement == item) ? 'warning' : 'primary'" 
                  class="w-100" 
                  elevation="6"
                  @click.stop="statementUpdate(item)"
                >
                  {{$t('message.statement.short.'+key)}}
                </v-btn>
              </v-col>
            </v-row>  
            <v-row justify="center">
              <v-col cols="10" class="my-0 py-0 q-label text-start">
                <v-switch 
                  v-if="booktimeState"
                  hide-details
                  color="success"
                  @change.stop="statementUpdate(-1)" 
                  class="my-0 py-0 q-switch-label" 
                  v-model="quClosed" 
                >
                  <template v-slot:label>
                    <span class="q-switch-label">
                      {{$t('message.statement.closed'+(qu.closed ? '[1]' : '[0]'))}}
                    </span>
                  </template>
                </v-switch>
                <p v-else class="my-4">
                  <span class="q-switch-label">
                    {{$t('message.statement.closed[2]')}}
                  </span>
                </p>              
              </v-col>
              <v-col cols="2 text-end">
                <q-icon icon="info" class="mb-n1" size="1" light="50" @click.stop="quInfo = !quInfo"/>
              </v-col>
            </v-row>   
            <v-row v-if="quInfo" justify="center">
              <v-col cols="12" class="my-0 py-0 text-start">
                <v-virtual-scroll
                  :height="200"
                  :items="[0,1,2,3,4,5,6,7,8,9,10]"
                  >
                  <template v-slot:default="{ item }">
                    <p class="q-small mt-2 mr-1 text-grey">{{$t('message.main.guide['+item+']')}}</p>
                  </template>
                </v-virtual-scroll>
              </v-col>
            </v-row>            
            </template>         
          </v-container>
        </div>
      </template>      
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      location="start"
      color="background"
      temporary
    >
      <v-list-item class="text-start mt-4" @click.stop="openUrl($t('message.main.landingUrl'))">
        {{$t('message.navbar.about')}}
      </v-list-item>
      <v-list-item @click.stop="menuSelect(2)" class="text-start text-primary">
        {{$t('message.navbar.account')}}
      </v-list-item>      
      <v-divider></v-divider>    
      <template v-if="config.queues?.length > 0">
        <v-list-item-subtitle class="text-start pa-4">
          {{$t('message.navbar.queues')}}
        </v-list-item-subtitle>      
        <v-list-item 
          v-for="(item, key) in config.queues" 
          :key="key" 
          @click.stop="queueSelect(item.token)" 
          :class="'text-start text-primary' + ((item.token == qu.token) ? ' bg-background_yy': ' bg-white')"
        >
          {{item.title}}
        </v-list-item> 
        <v-divider></v-divider>
      </template>            
      <v-divider></v-divider> 
      <v-list-item-subtitle class="text-start pa-4">
        {{$t('message.navbar.lang')}}
      </v-list-item-subtitle>
      <template v-for="(item, key) in langs" :key="key">
        <v-list-item 
          v-if="item.valid" 
          @click.stop="langSelect(item.name)" 
          :class="'text-start' + ((item.name == config.locale) ? ' bg-background_yy' : ' bg-white')"
        >
          {{item.title}}
        </v-list-item>
      </template>
      <template v-if="def.log">
        <v-divider></v-divider>
        <v-list-item class="text-start pa-4" @click.stop="dialogStorageCleanup">
          {{$t('message.main.wipe')}}
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item-subtitle class="text-start pa-4">
        eQueue Admin 1.3.4<br/>
        © LaFuente 2024<br/>
        Build 241031
      </v-list-item-subtitle>      
    </v-navigation-drawer>
    <v-main class="pb-8">
      <v-container v-if="qMenu == 1" style="max-width: 600px">
        <template v-if="!qu.id">
          <v-fade-transition>
            <v-row v-show="welcome == 2" justify="center">
              <v-col cols="10">
                <img src="./assets/scan.png" class="mt-4 w-100"/>
              </v-col>
            </v-row>
          </v-fade-transition>
        </template>
        <template v-if="qu.id" >
          <template v-if="quMode">
            <v-row justify="center" v-if="qu.points.length">
              <v-col cols="12" class="my-0 pt-6 pb-8">
                <q-grid-plus :qu="qu" :config="config" :statement="qu.statement" :timestamp="timestamp" @action="pointAction"/>
                <q-grid-xp :qu="qu" :config="config" :statement="qu.statement" :timestamp="timestamp" @action="pointAction"/>
              </v-col>
            </v-row>
            <v-row justify="center" v-else>
              <v-col cols="12" class="my-6 py-0">
                <h3>{{$t('message.main.queueEmpty')}}</h3>
              </v-col>
            </v-row>
          </template>          
          <template v-else>
            <template v-if="qu.shedules?.workdays?.length">
              <v-row class="my-1">
              </v-row>
              <template v-if="sheduleState != shedule_state.holiday">
                <v-row justify="center">
                  <v-col cols="12" class="my-0 py-0">
                    <h3 class="text-primary">{{$t('message.main.worktime')}}:</h3>
                    <h3 class="text-warning">{{qu.shedule?.workday?.from}} – {{qu.shedule?.workday?.to}}</h3>
                  </v-col>
                </v-row>
                <v-row v-if="qu.shedule?.breaks?.length" justify="center">
                  <v-col cols="12" class="my-0 py-0">
                    <h3 class="text-primary">{{$t('message.main.timebreaks',qu.shedule?.breaks?.length-1)}}:</h3>
                    <h3 v-for="(brk, idx) in qu.shedule.breaks" :key="idx" class="text-warning">
                      {{brk.from}} – {{brk.to}}
                    </h3>
                  </v-col>
                </v-row>
                <v-row v-if="qu.shedule?.booktime?.length" justify="center">
                  <v-col cols="12" class="my-0 py-0">
                    <h3 class="text-primary">{{$t('message.statement.closed[0]')}}:</h3>
                    <h3 v-for="(bk, idx) in qu.shedule.booktime" :key="idx" class="text-warning">
                      {{bk.from}} – {{bk.to}}
                    </h3>
                  </v-col>
                </v-row>
              </template>
              <v-row v-else justify="center">
                <v-col cols="12" class="my-0 py-0">
                  <h3 class="text-primary">{{$t('message.main.holiday')}}</h3>
                </v-col>
              </v-row>
            </template>
            <v-divider class="my-5"/> 
            <template v-if="sheduleState != shedule_state.holiday">     
              <v-row justify="center">
                <v-col cols="12" class="my-0 py-0">
                  <h3 class="text-primary">{{$t('message.main.statement')}}:</h3>
                  <h3 class="text-warning">
                    {{$t('message.main.statements['+sheduleState+']')}}
                  </h3>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" class="my-0 py-0">
                  <template v-for="(item, key) in statement" :key="item">
                    <p class="my-0 py-0" v-if="qu.statement == item">{{$t('message.statement.'+key)}}</p>
                  </template>
                </v-col>
              </v-row>
            </template>
            <v-sheet elevation="2" :class="'v-pointer mt-14 mb-4 pt-4 pb-4 w-100 rounded '+(setupDialog == -1 ? 'bg-background_y' : '')">
                <h3 class="text-center" @click.stop="toggleShedule(-1)">
                  {{$t('message.main.queueSettings')}}
                </h3>
            </v-sheet>
            <v-fade-transition>
              <q-setup :qu="qu" :config="config" v-if="setupDialog == -1" @save="saveSetup"/>
            </v-fade-transition>
            <template v-for="(category, key) in shedule" :key="key">
              <v-sheet elevation="2" :class="'v-pointer mt-4 mb-4 pt-4 pb-4 w-100 rounded '+(setupDialog == category ? 'bg-background_y' : '')">
                  <h3 class="text-center" @click.stop="toggleShedule(category)">
                    {{$t('message.main.sheduleSettings['+(category-1)+']')}}
                  </h3>
              </v-sheet>
              <v-fade-transition>
                <q-shedule v-if="setupDialog == category" :qu="qu" :category="category" @add="sheduleAdd = true" @kill="killShedule" />
              </v-fade-transition>
            </template>
            <v-sheet elevation="2" :class="'v-pointer mt-4 mb-4 pt-4 pb-4 w-100 rounded '+(setupDialog == -3 ? 'bg-background_y' : '')">
                <h3 class="text-center" @click.stop="toggleShedule(-3)">
                  {{$t('message.main.qrAndLinks')}}
                </h3>
            </v-sheet>            
            <v-fade-transition>
              <v-row justify="center" v-if="setupDialog == -3">
                <v-col cols="8 q-small text-left">
                  <a :href="def.clientHomeUrl+'id/'+qu.token" target="_blank">{{$t('message.main.link')}}</a><br/>
                  {{$t('message.main.qrStart')}}
                </v-col>
                <v-col cols="4" class="text-end">
                  <v-btn size="default" color="success" class="w-100" @click.stop="showQr(0,'qrQueue')">
                    <q-icon icon="qrcode"/>
                  </v-btn>
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-fade-transition>
              <v-row justify="center" v-if="setupDialog == -3">
                <v-col cols="8 q-small text-left">
                  <a :href="def.clientHomeUrl+'ok/id/'+qu.token" target="_blank">{{$t('message.main.link')}}</a><br/>
                  {{$t('message.main.qrEnd')}}
                </v-col>                
                <v-col cols="4" class="text-end">
                  <v-btn size="default" color="primary" class="w-100" @click.stop="showQr(1,'qrComplete')" >
                    <q-icon icon="qrcode"/>
                  </v-btn>                                    
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-fade-transition>
              <v-row justify="center" v-if="setupDialog == -3">
                <v-col cols="12" class="q-small text-center">
                  {{$t('message.main.qrPrint')}}
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-fade-transition>
              <v-divider class="my-4" v-if="setupDialog == -3"/>
            </v-fade-transition>
            <v-fade-transition> 
              <v-row class="my-0 py-0" justify="center" v-if="tokenAlert && (setupDialog == -3)">
                <v-col cols="12">
                  <p>{{ $t("message.main.changeTokenInfo") }}</p>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    hide-details
                    color="success"
                    class="my-0 py-0 q-switch-active"
                    v-model="tokenAlertAgree"
                  >
                    <template v-slot:label>
                      <span class="q-switch-label text-left text-black">
                        {{ $t("message.main.agree") }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-fade-transition>
              <v-row class="my-0 py-0" justify="center" v-if="setupDialog == -3">
                <v-col cols="12" md="6">
                  <v-btn
                    :disabled="tokenAlert && !tokenAlertAgree"
                    color="warning"
                    class="w-100"
                    elevation="6"
                    @click.stop="changeToken"
                  >
                    {{ $t("message.main.changeToken") }}
                  </v-btn>
                </v-col>
              </v-row> 
            </v-fade-transition>                      
            <v-sheet elevation="2" :class="'v-pointer mt-4 mb-4 pt-4 pb-4 w-100 rounded '+(setupDialog == -2 ? 'bg-background_y' : '')">
                <h3 class="text-center" @click.stop="toggleShedule(-2)">
                  {{$t('message.main.otherSettings')}}
                </h3>
            </v-sheet>
            <v-fade-transition>
              <q-other-setup :qu="qu" :config="config" v-if="setupDialog == -2" @cleanQueue="cleanQueue" @killQueue="killQueue"/>
            </v-fade-transition>          
            <v-row class="my-16">
            </v-row>
          </template> 
        </template>
      </v-container>
      <v-snackbar
        v-model="virtualPoint"
        timeout="-1"
        variant="elevated"
        rounded="xl"
        color="rgba(0,0,0,0.3)"
        class="pa-0 v-pointer"
        @click.stop="pointAdd"
      >
        <q-icon size="2" light="0" class=" mb-n2" icon="plus" @click.stop="pointAdd"/>
        <span class="ml-5">{{ $t('message.shedule.addNew')}}</span>
      </v-snackbar>        
      <q-profile 
        :config="config" 
        v-if="qMenu == 2" 
        @wipe="wipeAccount"
        @create="createAccnt" 
        @addQ="addNewQueue"
        @timezone="timezoneSet"
        @cancel="cancelAccntCreation()"
      />
    </v-main>
  </v-layout>
  </div>   
</v-card>
  <v-overlay 
    v-model="busy" 
    persistent
    contained
    class="align-center justify-center"
    :opacity="0.75"
    color="black"      
  >  
    <div class="text-center">
          <v-progress-circular
          indeterminate
          style="opacity: 0.6"
          :size="300"
          :width="60"
          color="white"         
          ></v-progress-circular> 
    </div>
  </v-overlay> 
  <shedule-picker 
    :mask="(setupDialog == 1) ? workdaysMask : 0x7F"
    v-model="sheduleAdd" 
    @save="saveShedule"
    :type="setupDialog"
  />
  <v-dialog v-model="dialogs.show" persistent width="500">
    <v-card :title="dialogs.title" class="rounded-xl" color="background">
      <v-card-text v-html="dialogs.theme"/>
      <v-card-text v-if="dialogs.textarea">
        <v-textarea 
          :label="dialogs.textarea"
          no-resize
          autofocus
          hide-details
          rows="2"
          variant="solo"
          v-model="dialogs.data.text"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialogs.action"
          class="ma-3 bg-warning"
          elevation="6"
          @click.stop="dialogs.action"
        >{{dialogs.actionTitle}}</v-btn>        
        <v-btn
          v-if="dialogs.close && !dialogs.closeTitle"
          class="ma-3 bg-primary"
          elevation="6"
          @click.stop="dialogs.close"
        >{{$t('message.dialogs.close')}}</v-btn>
        <v-btn
          v-if="dialogs.close && dialogs.closeTitle"
          class="ma-3 bg-primary"
          elevation="6"
          @click.stop="dialogs.close"
        >{{dialogs.closeTitle}}</v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import { apiGet, wsSubscribe, wsUnsubscribe } from './Api2.js';
import { structuredMerge } from './sm.js';
import { DEF, API_ERROR, QU_STATEMENT, SHEDULE_STATE, POINT_ACTIONS, SETUP, SHEDULE, CONFIG_STORAGE } from "./Defaults.js";
//import { initializeApp } from "firebase/app";
//import { getMessaging, getToken } from "firebase/messaging";
//import { ref } from "vue";
//import { TimePicker } from "vue-material-time-picker";
//import "vue-material-time-picker/dist/style.css";
//let Sha = require('sha.js')
import QIcon from './components/QIcon.vue';
import ShedulePicker from './components/ShedulePicker.vue';
import QGridXp from './components/QGridXp.vue';
import QGridPlus from './components/QGridPlus.vue';
import QSetup from './components/QSetup.vue';
import QOtherSetup from './components/QOtherSetup.vue';
import QShedule from './components/QShedule.vue';
import QProfile from './components/QProfile.vue';
// eslint-disable-next-line

/*
+ daily restart numbering - check!!!
+ push to prompt - check!!
+ kill breaks on add/kill workday
?? apply timezones to shedule add
+ send eMail on account creation confirmRegistration()
+ account creation dialogs
+ red badge on empty queues
+ default page/queue
+ getQueues errors processing
+ createAccount errors processing
+ initial state (no user data) - create account prompt
+ config.qu: current queue
+ How default queue loading??
+ Kill double run() on start
+ readCurrentQueue() must do all
+ qSetup total validation
+ dialog('wipeConfirm')
+ error.autorisation need cleanup storage
+ qProfile::addQu()
+ addNewQueue()
+ $qu->allowedByShedule() error!
+ and is it checked on new book?
+ shedule.allowed update by timer ??
+ @changeToken action
+ where new QR?
+ urgentPoint 2 variants
+ urgentPoint not ping queue error!
+ numero
+ extended v-app-bar
+ backend wipe Account and errors processing
+ timezone settings
+ Cleanup Queue
+ Cleanup Queue on Setup 
+ url id: token or idx
+ Timepicker
+ Shedule grid From/to ..
+ Cron sheduled
+ Delete serviced client
+ Post-service push - need Queue name
+ Daily refresh:
  + Complete unserviced points. If !keep_daily
  + Delete serviced points. If restart_daily || restart_empty
  + Kill locked points. Allways
+ Incorect display service_time
+ QR-buttons and links
+ Grid buttons
+ drag-n-drop shedule grid:
  + item.order index
  + add bottom position (0)
  + deny move -1
+ Waiting days/hours if too long
? config.Skip permission - for clients only
? Show account info
? this.wsLocker   Block ws during self-changes. Is it need?
+ Allow PWA
+ Угода користувача, особисті дані

Shevrones on tabs

*/

export default {
  name: 'App',
  components: {
    QIcon,
    QGridXp,
    QGridPlus,    
    QSetup,
    QOtherSetup,    
    QShedule,
    QProfile,
    ShedulePicker
  },
  data() {
    return {
      def: DEF,   
      statement: QU_STATEMENT, 
      point_actions: POINT_ACTIONS,
      shedule_state: SHEDULE_STATE,
      setup: SETUP,
      shedule: SHEDULE,
      langs: DEF.langs,
      drawer: false,
      quMode: false,
      quInfo: false,
      qu: structuredClone(DEF.qu),
      config: structuredClone(DEF.config),    // User config, not queue config
      dialogs: structuredClone(DEF.dialogs),
      welcome: 0,           // 2 - allow welcome page 
      wsLocker: false,      // Lock to process websockets ping
      busy: false,
      busyFlag: false,
      fbApp: null,
      fbMessaging: null,
      stillTrue: true,
      stillFalse: false,
      week:[false,false,false,false,true,true,false],
      weekMask:[true,true,true,true,false,false,false],
      sheduleAdd: false,
      setupDialog: 0,
      qMenu: 1,
      timestamp: 0,
      timestampShift: 0,
      tokenAlertAgree: false,
      tokenAlert: false,
      colorQr: false,
      qrBorder: 0,
      qrWidth: 0
    }
  },        
  mounted() {
    document.getElementById('app').style.marginTop = 0;    
    setInterval(()=>{
      this.timestamp = Math.floor(new Date().getTime()/1000 + this.timestampShift);
    }, DEF.timestampTimer);
    this.loadConfig();
    this.$i18n.locale = this.config.locale;
    this.$i18n.fallbackLocale = this.config.locale; 
    if(this.config.user) {
      if(this.config.qu) {
        this.queueSelect(this.config.qu, false);
      }
      this.run();
    } else {    // No yet registred
      this.menuSelect(2);
    }
  },
  computed: {
    quClosed: {
      get() {
        return !this.qu.closed;
      },
      set(value) {
        this.qu.closed = !value;
      }
    },
    virtualPoint: {
      get() {
        return this.quMode && !this.qu.closed && this.booktimeState;
      },
      set(value) {
        this.qu.closed = !value;
      }
    },
    workdaysMask() {
      return (0x7F & (~this.workdaysExists));
    },
    workdaysExists() {
      let result = 0;
      this.qu.shedules?.workdays?.forEach(day => {
        result |= day?.filter?.week;
      });
      return result;
    },
    queuesList() {
      return [];
    },
    booktimeState() {
      let state = false;
      if(this.qu.shedule) {
        if(this.qu.shedule.booktime) {
          this.qu.shedule.booktime.forEach(book => {
            if((book.from_stamp <= this.timestamp)
              && (book.to_stamp >= this.timestamp)) {
              state = true;
            }            
          });
        }
      }
      return state;
    },
    sheduleState() {
      let state = SHEDULE_STATE.holiday;
      if(this.qu.shedule) {
        if(this.qu.shedule.workday) {
          if((this.qu.shedule.workday.from_stamp <= this.timestamp)
            && (this.qu.shedule.workday.to_stamp >= this.timestamp)) {
            state = SHEDULE_STATE.work;
            if(this.qu.shedule.breaks) {
              this.qu.shedule.breaks.forEach(brk => {
                if((brk.from_stamp <= this.timestamp)
                  && (brk.to_stamp >= this.timestamp)) {
                  state = SHEDULE_STATE.break;
                }
              });
            }
          } else {
            state = SHEDULE_STATE.closed;
          }
        }
      }
      return state;
    }
  },
  methods: {
    log(data) {
      if(DEF.log) {
        console.log(data);
      }
    },
    reboot() {
      window.location.replace('/');
    },
    run() {
      this.log('RUN Lunched');
      this.drawer = false;
      wsUnsubscribe();
      this.readQueues(true).then((err) => {
        if(!err) {
          if(this.config.queues.length) {
            if(this.qu.token) {
              let quConfirmation = false;       // Last Queue yet is allowed
              this.config.queues.forEach((qu) => {
                if(qu.token == this.qu.token) {
                  quConfirmation = true;
                }
              });
              if(!quConfirmation) {
                this.qu.token = null;
              }
            }
            if(!this.qu.token && this.config.queues[0].token) {  // get token of Default [0] Queue
              this.qu.token = this.config.queues[0].token;
            }
            this.readCurrentQueue(true);
          } else {
            this.qMenu = 2;
          }
        } else {
          switch(err) {
            case API_ERROR.autorisation:
              this.dialogBadAutorisation();
              break;
            case API_ERROR.request:            
              this.dialogErrorRequest();
              break;          
            default:
          }
        }
      }).catch((err)=>{
        this.log(err.message);
        this.dialogApiError();
      });
    },
    startOverlay() {
      this.busyFlag = true;
      window.setTimeout(()=>{
        if(this.busyFlag) {
          this.busy = true;
        }
      }, DEF.overlayTimeout);
    },
    stopOverlay() {
      this.busyFlag = false;
      this.busy = false;
    },
    dialogOff() {
      this.dialogs.show = false;
    },
    dialog(data) {
      this.dialogs = structuredClone(DEF.dialogs);
      this.dialogs.close = this.dialogOff;
      for(let key in data) {
        this.dialogs[key] = data[key];
      }
      this.dialogs.show = true;
    },
    dialogApiError() {
      this.dialog({
        theme: this.$t('message.error.apiError'),
        title: this.$t('message.error.title'),
        actionTitle: this.$t('message.main.wipeShort'),
        action: () => {
          this.dialogOff();
          this.dialogStorageCleanup();
        },
        closeTitle: this.$t('message.error.reboot'),
        close: () => {
          this.dialogOff();
          this.reboot();
        }
      });
    },     
    dialogErrorRequest() {
      this.dialog({
        theme: this.$t('message.error.request'),
        title: this.$t('message.error.title'),
        actionTitle: this.$t('message.main.wipeShort'),
        action: () => {
          this.dialogOff();
          this.dialogStorageCleanup();
        },
        closeTitle: this.$t('message.error.reboot'),
        close: () => {
          this.dialogOff();
          this.reboot();
        }      
      });
    },    
    dialogBadAutorisation() {
      this.dialog({
        theme: this.$t('message.error.autorisation'),
        title: this.$t('message.error.title'),
        actionTitle: this.$t('message.main.wipeShort'),
        action: () => {
          this.dialogOff();
          this.dialogStorageCleanup();
        },
        closeTitle: this.$t('message.error.reboot'),
        close: () => {
          this.dialogOff();
          this.reboot();
        }       
      });
    },
    dialogStorageCleanup() {
      this.dialog({
        theme: this.$t('message.main.wipeInfo'),
        title: this.$t('message.main.wipe'),
        actionTitle: this.$t('message.main.wipeShort'),
        action: () => {
          this.dialogOff();
          this.storageClear();
          this.reboot();
        },
        close: () => {
          this.dialogOff();
          this.reboot();
        } 
      });
    },
    menuSelect(id) {
      this.qMenu = id,
      this.drawer = false
    },
    openUrl(url) {
      window.location.replace(url,"_blank");
    },
    queueSelect(token, store = true) {
      this.qu.token = token;
      if(store) {
        this.config.qu = token;
        this.saveConfig();
        this.run();   
        this.setupDialog = 0;
      }
    },
    toggleShedule(type) {
      this.setupDialog = (this.setupDialog == type) ? 0 : type;
    },
    addShedule() {  // ????
      //this.setupDialog = type;
      this.sheduleAdd = true;
    },
    langSelect(lang) {
      this.config.locale = lang;
      this.saveConfig();
      this.reboot();
    },
    saveSetup() {
      this.dialog({
        theme: this.$t('message.dialogs.saveSetup'),
        title: this.$t('message.dialogs.confirmTitle'),
        actionTitle: this.$t('message.main.save'),
        action: ()=>{
          //alert('Save setup');
          this.dialogOff();
          this.actionCurrentQueue('setConfig', {title: this.qu.title, config: this.qu.config}, true).then((result)=>{
            switch(result?.error) {
              case API_ERROR.autorisation:
                this.dialogBadAutorisation();
                break;
              case API_ERROR.request:            
                this.dialogErrorRequest();
                break;
              case API_ERROR.noQueue:
                this.dialog({
                  theme: this.$t('message.error.noQueue'),
                  title: this.$t('message.error.title')
                });
                break;
              default:
                this.setupDialog = 0;
                this.run();
                this.dialog({
                  theme: this.$t('message.shedule.saved'),
                  title: this.$t('message.main.queueSettings')
                });
                break;
            }
          }).catch(()=>{
            this.setupDialog = 0;
            this.dialogApiError();        
          });
        }
      });
    },
    killShedule(id) {
      this.dialog({
        theme: this.$t('message.shedule.killWorkday'),
        title: this.$t('message.shedule.type['+(this.setupDialog-1)+']'),
        actionTitle: this.$t('message.main.delete'),
        action: () => {
          this.saveShedule({id: id, type: this.setupDialog}, 'delShedule');
          this.dialogOff();
        }
      });      
    },
    saveShedule(data, method = 'setShedule') {
      //alert(JSON.stringify(data));
      this.actionCurrentQueue(method, data, true).then((result)=>{
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;
          case API_ERROR.setupDialog:
            this.dialog({
              theme: this.$t('message.error.setupDialog'),
              title: this.$t('message.error.title')
            });
            break;            
          case API_ERROR.request:            
          case API_ERROR.sheduleData:
            this.dialog({
              theme: this.$t('message.error.sheduleData'),
              title: this.$t('message.error.title')
            });
            break;            
          case API_ERROR.sheduleWeekdayDuplicate:
            this.dialog({
              theme: this.$t('message.error.sheduleWeekdayDuplicate'),
              title: this.$t('message.shedule.type['+(data.type-1)+']')
            });
            break;            
          case API_ERROR.sheduleTimeCrossing:
            this.dialog({
              theme: this.$t('message.error.'+((data.type == SHEDULE.break) ? 
                'sheduleTimeCrossingBreak' : 'sheduleTimeCrossingBook')),
              title: this.$t('message.shedule.type['+(data.type-1)+']')
            });
            break;
          case API_ERROR.sheduleTimeNoInside:
            this.dialog({
              theme: this.$t('message.error.sheduleTimeNoInside'),
              title: this.$t('message.shedule.type['+(data.type-1)+']')
            });
            break;                
          case API_ERROR.noQueue:
            this.dialog({
              theme: this.$t('message.error.noQueue'),
              title: this.$t('message.error.title')
            });
            break;
          default:  
            /* 
            this.dialog({
              theme: this.$t('message.shedule.saved'),
              title: this.$t('message.shedule.type['+(data.type-1)+']')
            });
            */
            this.run();
            break;             
        }
      }).catch(()=>{
        this.dialogApiError();       
      });
    },
    statementUpdate(state = -1) {
      if(state) {
        this.actionCurrentQueue((state >= 0) ? 'setStatement' : 'setClosed', 
          {statement: state, closed: this.qu.closed}, true).then((result)=>{
          if(result?.error && (state < 0)) {
            this.qu.closed = !this.qu.closed;
          }
          switch(result?.error) {
            case API_ERROR.autorisation:
              this.dialogBadAutorisation();
              break;           
            case API_ERROR.request:            
              this.dialogErrorRequest();
              break;               
            case API_ERROR.noQueue:
              this.dialog({
                theme: this.$t('message.error.noQueue'),
                title: this.$t('message.error.title')
              });
              break;
            default:   
              if(state >= 0) {
                this.qu.statement = state;
                this.run();
              }
              break;             
          }
        }).catch(()=>{
          this.dialogApiError();        
        });
        //alert(this.qu.closed);
      } else {
        this.dialog({
          theme: this.$t('message.statement.serviceAlert'),
          title: this.$t('message.main.statement')
        });          
      }
    },
    pointAdd() { 
      this.pointAction({method: 'new'});      
    },
    pointAction(action) { 
      this.dialog({
        theme: this.$t('message.dialogs.point_'+action?.method),
        title: this.$t('message.dialogs.confirmTitle'),
        actionTitle: this.$t('message.dialogs.confirm'),
        textarea: (action?.method == 'recall') ? this.$t('message.dialogs.userMessage') : null,
        action: ()=>{
          this.dialogOff();
          let data = {point: action?.token, point_to: action?.to, text: this.dialogs.data?.text};
          this.actionCurrentQueue(action?.method+'Point', data, true).then((result)=>{
            switch(result?.error) {
              case API_ERROR.pointComplete:
                this.dialog({
                  theme: this.$t('message.error.pointComplete'),
                  title: this.$t('message.error.title')
                });
                break;
              case API_ERROR.pointNotComplete:
                this.dialog({
                  theme: this.$t('message.error.pointNotComplete'),
                  title: this.$t('message.error.title')
                });
                break;  
              case API_ERROR.urgent:
                this.dialog({
                  theme: this.$t('message.error.complete'),
                  title: this.$t('message.error.title')
                });
                break;                                
              case API_ERROR.complete:
                this.dialog({
                  theme: this.$t('message.error.complete'),
                  title: this.$t('message.error.title')
                });
                break; 
              case API_ERROR.dnd:
                this.dialog({
                  theme: this.$t('message.error.dnd'),
                  title: this.$t('message.error.title')
                });
                break;                  
              case API_ERROR.pointSkip:
                this.dialog({
                  theme: this.$t('message.error.pointSkip'),
                  title: this.$t('message.error.title')
                });
                break;                                               
              case API_ERROR.autorisation:
                this.dialogBadAutorisation();
                break;           
              case API_ERROR.request:            
                this.dialogErrorRequest();
                break;               
              case API_ERROR.noQueue:
                this.dialog({
                  theme: this.$t('message.error.noQueue'),
                  title: this.$t('message.error.title')
                });
                break;
              default:  
                this.run();
                break;             
            }
          }).catch(()=>{
            this.dialogApiError();       
          });         
        },
        /*
        data: {   // Maybe no need
          idx: action?.idx,
          point: action?.point
        }
        */
      });
    },    
    showQr(id, title) {
      this.colorQr = true;
      this.qrBorder = 0;
      this.dialog({
        theme: '<a id="qr-code-link" href="#" target="_blank" download="'+this.qu.title+' qr-code-'+(id+1)+'.jpg"><div class="text-center" id="qr-code"></div></a>',
        title: this.$t('message.dialogs.'+title),
        actionTitle: this.$t('message.dialogs.'+(this.colorQr ? 'bw' : 'color')),
        action: ()=>{
          this.colorQr = !this.colorQr;
          document.querySelector('canvas').remove();
          this.qrMake("qr-code",this.qu.qr_code[id],100,'#ffff00');
          document.getElementById("qr-code-link").setAttribute("href", document.querySelector("canvas").toDataURL('image/jpeg', 0.8));
          this.dialogs.actionTitle = this.$t('message.dialogs.'+(this.colorQr ? 'bw' : 'color'));
        }
      });
      this.$nextTick(()=> {
        this.qrMake("qr-code",this.qu.qr_code[id],100,'#ffff00');
        document.getElementById("qr-code-link").setAttribute("href", document.querySelector("canvas").toDataURL('image/jpeg', 0.8));
      });             
    },
    qrMake(containerId, base64data, widthPercent = 100, cornersColor = "#ffffe0") {
      const cntnr = document.getElementById(containerId);
      const qrData = atob(base64data);
      const qrSize = Math.floor(Math.sqrt(qrData.length*8));
      let width = this.qrWidth;
      let qrBorder = this.qrBorder;
      let qrRatio;
      if(this.qrBorder) {
        qrRatio = Math.floor((width-2*qrBorder)/qrSize);
      } else {                
        width = Math.floor(widthPercent * cntnr.getBoundingClientRect().width / 100);
        qrBorder = Math.max(10,Math.floor(0.05*width));
        qrRatio = Math.floor((width-2*qrBorder)/qrSize);
        qrBorder = Math.floor((width-qrRatio*qrSize)/2);
        this.qrBorder = qrBorder;
        this.qrWidth = width;
      }
      let pos=0;
      const cnv = document.createElement("canvas");
      cnv.setAttribute('width',width);
      cnv.setAttribute('height',width);
      cntnr.appendChild(cnv);
      const canvas = cnv.getContext("2d");
      canvas.fillStyle = "white";
      canvas.fillRect(0,0,qrSize*qrRatio+2*qrBorder, qrSize*qrRatio+2*qrBorder);
      for(let idx=0; idx<qrData.length; idx++) {
        let qbyte = qrData.charCodeAt(idx);
        for(let shift=0; shift<8; shift++) {
          let y = Math.floor(pos/qrSize);
          let x = pos++ % qrSize;
          
          if(qbyte & 128) {           
            canvas.fillStyle = (this.colorQr && pos%2) ? "blue" : "black";
            if(this.colorQr) {
              canvas.beginPath();
              canvas.arc((qrRatio*(x+0.5))+qrBorder, (qrRatio*(y+0.5))+qrBorder, 0.45*qrRatio, 0, 2 * Math.PI);
              canvas.fill();
            } else {
              canvas.fillRect((qrRatio*x)+qrBorder, (qrRatio*y)+qrBorder, qrRatio, qrRatio);
            }
          }
          qbyte = qbyte << 1;
        }
      }
      canvas.fillStyle = "black";      
      canvas.fillRect(qrBorder, qrBorder, 7*qrRatio, 7*qrRatio);
      canvas.fillRect(qrBorder+qrRatio*(qrSize-7), qrBorder, 7*qrRatio, 7*qrRatio);
      canvas.fillRect(qrBorder, qrBorder+qrRatio*(qrSize-7), 7*qrRatio, 7*qrRatio);      
      canvas.fillStyle = this.colorQr ? cornersColor : 'white';
      canvas.fillRect(qrBorder+qrRatio, qrBorder+qrRatio, 5*qrRatio, 5*qrRatio);
      canvas.fillRect(qrBorder+qrRatio*(qrSize-6), qrBorder+qrRatio, 5*qrRatio, 5*qrRatio);
      canvas.fillRect(qrBorder+qrRatio, qrBorder+qrRatio*(qrSize-6), 5*qrRatio, 5*qrRatio);
      canvas.fillStyle = "black";
      if(this.colorQr) {
        canvas.beginPath();
        canvas.roundRect(qrBorder+2*qrRatio, qrBorder+2*qrRatio, 3*qrRatio, 3*qrRatio, [0,1.5*qrRatio,0,1.5*qrRatio]);
        canvas.fill();
        canvas.beginPath();
        canvas.roundRect(qrBorder+qrRatio*(qrSize-5), qrBorder+2*qrRatio, 3*qrRatio, 3*qrRatio, [1.5*qrRatio,0,1.5*qrRatio,0]);
        canvas.fill();
        canvas.beginPath();
        canvas.roundRect(qrBorder+2*qrRatio, qrBorder+qrRatio*(qrSize-5), 3*qrRatio, 3*qrRatio, [1.5*qrRatio,0,1.5*qrRatio,0]);	
        canvas.fill();
      } else {
        canvas.fillRect(qrBorder+2*qrRatio, qrBorder+2*qrRatio, 3*qrRatio, 3*qrRatio);
        canvas.fillRect(qrBorder+qrRatio*(qrSize-5), qrBorder+2*qrRatio, 3*qrRatio, 3*qrRatio);
        canvas.fillRect(qrBorder+2*qrRatio, qrBorder+qrRatio*(qrSize-5), 3*qrRatio, 3*qrRatio);
      }
    },
    addNewQueue(pattern) {
      this.actionAccount('newQueue', {
          user: this.config.user,
          pattern: pattern.value
        }, true).then(result => {
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;  
          case API_ERROR.queueCreation:
            this.dialog({
              theme: this.$t('message.error.queueCreation'),
              title: this.$t('message.error.title')
            });
            break;                     
          case API_ERROR.request:
            this.dialogErrorRequest();
            break;
          default:    // Put new queue to list, Set it as current, Dialog reboot
            this.dialog({
              theme: this.$t('message.main.queueCreated'),
              title: this.$t('message.main.queueCreation'),
              closeTitle: this.$t('message.error.reboot'),
              action: null,
              close: () => {
                this.dialogOff();
                if(result?.result) {
                  this.queueSelect(result?.result.token);
                } else {
                  this.reboot();
                }
              }
            });            
            break;
        }
      }).catch((err)=>{
        this.log(err.message);
        this.dialogApiError();
      });
    },
    wipeAccount() {
      this.dialog({
        theme: this.$t('message.main.wipeAcntInfo'),
        title: this.$t('message.main.wipeAcnt'),
        actionTitle: this.$t('message.main.delete'),
        action: () => {      
          this.actionAccount('wipeAccount', {
            user: this.config.user
          }, true).then(result => {
            switch(result?.error) {
              case API_ERROR.autorisation:
                this.dialogBadAutorisation();
                break;           
              case API_ERROR.request:
                this.dialogErrorRequest();
                break;
              default:
                this.storageClear();
                this.reboot();
                break;
            }
          }).catch((err)=>{
            this.log(err.message);
            this.dialogApiError();
          });
        }
      });
    },
    createAccnt(email) {
      this.actionAccount('newAccount', {
          email:email,
          user: DEF.master
        }, true).then(result => {
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;  
          case API_ERROR.creation:
            this.dialog({
              theme: this.$t('message.error.creation'),
              title: this.$t('message.error.title')
            });
            break;                     
          case API_ERROR.request:
            this.dialogErrorRequest();
            break;
          default:
            this.config = structuredMerge(DEF.config, result?.result);
            this.config.user = '';
            this.saveConfig();
            this.dialog({
              theme: this.$t('message.main.accountCreated'),
              title: this.$t('message.main.creationAccount'),
              actionTitle: this.$t('message.error.reboot'),
              action: () => {
                this.dialogOff();
                this.reboot();
              },
              close: null
            });            
            break;
        }
      }).catch((err)=>{
        this.log(err.message);
        this.dialogApiError();
      });
    },
    cancelAccntCreation() {
      this.storageUnset('key');
      window.location.replace('/');
    },
    cleanQueue(reset = false) {
      this.actionCurrentQueue('cleanQueue',{reset: reset}).then((result)=>{
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;
          case API_ERROR.request:            
            this.dialogErrorRequest();
            break;
          case API_ERROR.noQueue:
            this.dialog({
              theme: this.$t('message.error.noQueue'),
              title: this.$t('message.error.title')
            });
            break;
          default:
            this.reboot();
            break;
        }
      }).catch(()=>{
        this.setupDialog = 0;
        this.dialogApiError();        
      });
    },    
    killQueue() {
      this.actionCurrentQueue('killQueue').then((result)=>{
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;
          case API_ERROR.request:            
            this.dialogErrorRequest();
            break;
          case API_ERROR.noQueue:
            this.dialog({
              theme: this.$t('message.error.noQueue'),
              title: this.$t('message.error.title')
            });
            break;
          default:
            this.storageUnset('qu');
            this.reboot();
            break;
        }
      }).catch(()=>{
        this.setupDialog = 0;
        this.dialogApiError();        
      });
    },
    changeToken() {
      if (!this.tokenAlert) {
        this.tokenAlert = true;
        this.cleanAlert = false;  
        this.cleanAlertAgree = false;              
        this.killAlert = false;
        this.killAlertAgree = false;
      } else {
        this.changeQuToken();
      }
    },    
    changeQuToken() {
      this.actionCurrentQueue('changeToken').then((result)=>{
        switch(result?.error) {
          case API_ERROR.autorisation:
            this.dialogBadAutorisation();
            break;
          case API_ERROR.request:            
            this.dialogErrorRequest();
            break;
          case API_ERROR.noQueue:
            this.dialog({
              theme: this.$t('message.error.noQueue'),
              title: this.$t('message.error.title')
            });
            break;
          default:
            if(result.result?.token) {
              this.storageSet('qu', result.result.token);
            }
            this.reboot();
            break;
        }
      }).catch(()=>{
        this.setupDialog = 0;
        this.dialogApiError();        
      });
    },
// =================== API methods ============================
    async wsSubscribe() {
      this.wsLocker = false;
      return wsSubscribe(this.qu.token, this.onMessage);      
    },
    onMessage(msg) {
      if(this.qu.token == msg) {
        if(!this.wsLocker) {
          this.readCurrentQueue();
        }
      } else if(msg == 'error') {
        this.dialogApiError();
      }
    },
    timezoneSet(value) {
      if(this.config.user) {
        this.actionAccount('timezoneSet', {
            timezone:value,
            user: this.config.user
          }, true).then(result => {
            switch(result?.error) {
              case API_ERROR.autorisation:
                this.dialogBadAutorisation();
                break;           
              case API_ERROR.request:
                this.dialogErrorRequest();
                break;
              default:
                this.config.timezone = value;
                this.storageSet('timezone', value);
                break;
            }
          }).catch((err)=>{
            this.log(err.message);
            this.dialogApiError();
          });
      } else {
        this.config.timezone = value;
        this.storageSet('timezone', value);
      }
    },
    saveConfig() {
      for(let key in this.config) {
        if(CONFIG_STORAGE.includes(key)) {
           this.storageSet(key, this.config[key])
        }
      }
    },
    loadConfig() {
      for(let i=0; i<localStorage.length; i++) {
        let key = localStorage.key(i);
        if(CONFIG_STORAGE.includes(key)) {
          this.config[key] = window.localStorage.getItem(key);
        }
      }
    },    
    storageSet(key, value) {
      if(window.localStorage.getItem(key) != value) {
        window.localStorage.setItem(key, value);
      }
    },
    storageUnset(key) {
      window.localStorage.removeItem(key);
    },    
    storageClear() {
      window.localStorage.clear();
    },
    async actionAccount(method, data = {}, overlay = false) {
      return new Promise((resolve, reject) => {
        if(overlay) {
          this.startOverlay();
        }    
        let dataX = structuredClone(data);
        dataX.lang = this.config.locale;             
        apiGet({
          ver: DEF.apiVersion,
          method: method,
          data: dataX
        }).then((result) => {
          resolve(result);
        }).catch((err)=>{
          this.log(err.message);
          reject();
        }).finally(()=>{
          this.stopOverlay();
        });
      });
    },       
    async actionCurrentQueue(method, data = {}, overlay = false) {
      return new Promise((resolve, reject) => {
        if(this.qu.token) {
          if(overlay) {
            this.startOverlay();
          }    
          let dataX = structuredClone(data);
          dataX.user = this.config.user;
          dataX.lang = this.config.locale;
          dataX.token = this.qu.token;             
          apiGet({
            ver: DEF.apiVersion,
            method: method,
            data: dataX
          }).then((result) => {
            resolve(result);
          }).catch((err)=>{
            this.log(err.message);
            reject();
          }).finally(()=>{
            this.stopOverlay();
          });
        } else {
          resolve(API_ERROR.noQueue);
        }
      });
    },  
    async readQueues(overlay = false) {
      return new Promise((resolve, reject) => {
        if(overlay) {
          this.startOverlay();
        }
        apiGet({
          ver: DEF.apiVersion,
          method: "getQueues",
          data: {
            user: this.config.user,
            lang: this.config.locale           
          }
        }).then((result) => {
          if(result.error) {
            resolve(result.error);
          } else {
            this.config = structuredMerge(this.config, result?.request?.data?.user);
            this.config.queues = result?.result?.queues;
            resolve(null);
          }
        }).catch((err) => {
          reject(err);
        }).finally(() => {
          this.stopOverlay();
        });
      });
    },
    readCurrentQueueError(error) {
      switch(error) {
        case API_ERROR.autorisation:
          this.dialogBadAutorisation();
          break;           
        case API_ERROR.request:            
          this.dialogErrorRequest();
          break;               
        case API_ERROR.queue:   
          this.qu.token = null;
          this.config.qu = '';
          this.dialog({
            theme: this.$t('message.error.noQueue'),
            title: this.$t('message.error.title'),
            actionTitle: this.$t('message.error.reboot'),
            action: () => {
              this.dialogOff();
              this.reboot();
            },
            close: null            
          });
          break;
        case API_ERROR.noQueue:
          this.dialog({
            theme: this.$t('message.error.noQueue'),
            title: this.$t('message.error.title')
          });
          break;
        case API_ERROR.noConnection:          
        default:   
          this.dialogApiError();
          break;             
      }
    },
    readCurrentQueue(reconnect = false) {
      if(this.qu.token) {
        if(reconnect) {
          this.startOverlay();
        }
        apiGet({
          ver: DEF.apiVersion,
          method: "getQueue",
          data: {
            user: this.config.user,
            lang: this.config.locale,
            token: this.qu.token           
          }
        }).then((result) => {
          if(result.error) {
            this.readCurrentQueueError(result.error);
          } else {
            if(result.result?.sync) {     // Timer syncronisation
              this.timestampShift = result.result?.sync - Math.floor(new Date().getTime()/1000);
            }
            if(result.result?.timezone) {   // Timezone syncronisation
              this.config.timezone = result.result?.timezone;
              this.storageSet('timezone', result.result?.timezone);
            }            
            this.qu = structuredMerge(DEF.qu, result.result);
            this.menuSelect(1);
            if(reconnect) {
              this.wsSubscribe().then(()=>{

              }).catch((err)=>{
                this.log(err.message);
                this.readCurrentQueueError(API_ERROR.noConnection);
              });
            }
          }
        }).catch((err) => {
          this.log(err.message);
          this.readCurrentQueueError(API_ERROR.noConnection);
        }).finally(() => {
          this.stopOverlay();
        });
      } else {
        this.readCurrentQueueError(API_ERROR.noQueue);
      }
    }
  },
  watch: {
    qu: {
      deep: true,
      handler(value) {
        if(value.token) {
          document.querySelector('title').innerHTML = value?.title;
        } else {
          document.querySelector('title').innerHTML = this.$t('message.main.eQueue');
        }
        if(value.id) {
          this.welcome = 0;
        } else if(!this.welcome) {
          this.welcome = 1;
          window.setTimeout(()=>{
            this.welcome = 2;
          },DEF.welcomeTimeout);
        }
      }
    },
    quMode: {
      handler() {
        this.setupDialog = 0;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.background {
  background-color: 'ffffe8';
  height: 100vh;
  width: 100vw;
}
.v-pointer {
  cursor: pointer;
}
.q-btn-small .v-btn__content {
  font-size: 0.7em;
}
td.q-td {
  font-size: 1em;
  line-height: 1.1em;
}
.q-switch-label, .q-small {
  font-size: 0.9em;
}
.q-small2 {
  font-size: 0.8em;
}
.q-label .v-label {
  opacity: 1 !important;
}
h3 {
  color: grey;
}
</style>
