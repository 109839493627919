<template>
  <img :src="require('../assets/'+icon+'.svg')" :class="'q-icon-'+size" :style="'filter: invert('+light+'%)'"/>
</template>
<script>
export default {
  name: 'QIcon',
  props: {
    icon: String,
    size: {
      type: String,
      default: '2'
    },
    light: {
      type: String,
      default: '100'
    }
  }
}
</script>
<style scoped>
.q-icon-0 {
  height: 1em;
}
.q-icon-1 {
  height: 1.5em;
}
.q-icon-2 {
  height: 2em;
}
.q-icon-3 {
  height: 2.5em;
}
.q-icon-4 {
  height: 3em;
}
.q-icon-5 {
  height: 5em;
}
</style>