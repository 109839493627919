export default {
  "message": {
    "try": {
      "applesQty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["жодного яблука"]), _normalize([_interpolate(_named("n")), " яблуко"]), _normalize(["two_form"]), _normalize([_interpolate(_named("n")), " яблука"]), _normalize([_interpolate(_named("n")), " яблук"])])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домашня сторінка"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мій акаунт"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про проект"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова інтерфейсу"])},
      "queues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої черги"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з"])}
    },
    "main": {
      "landingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])},
      "cleanQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити чергу"])},
      "numbersReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А також примусово скинути нумерацію"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступний"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершити"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікує"])},
      "cleanQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі клієнти, що знаходяться зараз у черзі, будуть видалені."])},
      "changeToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити QR-коди"])},
      "changeTokenInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потреба змінити токен черги та її QR-коди може виникнути, якщо ваша черга стала предметом спаму або хуліганства. Ця дія безкоштовна. При оновленні токена чергу буде повністю очищено і вам доведеться роздрукувати нові QR-коди."])},
      "killQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити чергу"])},
      "killQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі дані, що пов'язані з цією чергою, будуть видалені без можливості відновлення."])},
      "getPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати преміум акаунт"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зрозуміло"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["-"]), _normalize(["1 доба"]), _normalize([_interpolate(_named("n")), " доби"]), _normalize([_interpolate(_named("n")), " діб"])])},
      "moreDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше доби"])},
      "tooFar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше тижня"])},
      "addQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити чергу"])},
      "eQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністрування е-черги"])},
      "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні вихідний день"])},
      "worktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий час сьогодні"])},
      "timebreaks": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Перерва"]), _normalize(["Перерви"])])},
      "tabInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інфо та налаштування"])},
      "statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний стан"])},
      "statements": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихідний день"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неробочий час"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерва"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий час"])}
      ],
      "queueManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування клієнтами у черзі"])},
      "inProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процесі"])},
      "queueEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черга порожня"])},
      "queueSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування черги"])},
      "sheduleSettings": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування робочого часу"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування перерв"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування періодів запису"])}
      ],
      "qrAndLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-коди та посилання"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
      "qrStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щоб стати у чергу"])},
      "qrEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щоб почати обслуговуватися"])},
      "qrPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережіть QR-коди, роздрукуйте їх та розташуйте у місці обслуговування"])},
      "otherSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інше"])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всі дані додатку"])},
      "wipeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалення даних додатку на пристрої не пошкодить вашому обліковому запису, створеним чергам та їх поточному стану. Ви можете відновити доступ на цьому або іншому пристрої."])},
      "wipeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистити дані"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити акаунт"])},
      "creationAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення акаунту"])},
      "queueCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення черги"])},
      "queueCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нову чергу створено. Виконайте налаштування для подальшого використання."])},
      "createAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете створити новий акаунт, або підключити цей пристрій до існуючого. В обох випадках для активації акаунту на пристрої потрібно отримати відповідне посилання на e-Mail. Після активації ви зможете використовувати додаток безкоштовно або підписатися на платну версію і отримати додаткові можливості у разі потреби."])},
      "accountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш акаунт створений."])},
      "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо вам потрібно адмініструвати свої черги з кількох приладів, виконайте реєстрацію на кожному з них, використовуючи той самий e-Mail."])},
      "wipeAcnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити акаунт"])},
      "wipeAcntInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш обліковий запис, всі створені вами черги та записи про клієнтів у чергах будуть видалені. Бескоштовні акаунти видаляються без можливості відновлення. Відновлення платних акаунтів можливе до закінчення сплаченого терміну дії. Повернення невикористаних коштів не передбачене."])},
      "accountCreatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримайте лист на зареєстрований e-Mail та перейдіть за посиланням, щоб завершити реєстрацію. Якщо щось пішло не так, ви можете скасувати поточну реєстрацію та створити нову на той самий e-Mail, або на інший."])},
      "cancelCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати реєстрацію"])},
      "privacyBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
      "areementBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угода користувача"])},
      "privacyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/privacy"])},
      "areementUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/agreement"])},
      "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я ознайомлений (-на) з $Угодою користувача$, $Політикою конфіденційності$ та приймаю умови цих документів."])},
      "timezoneSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть ваш часовий пояс"])},
      "timezoneInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування часового поясу необхідне для вірного відображення часу у додатках користувачів та адміністратора. Якщо ви та клієнти знаходитесь у різних часових поясах, встановіть пояс точки обслуговування."])},
      "buyPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придбати преміум"])},
      "buyPremiumInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преміум акаунт дозволяє використовувати додатковий функціонал додатку, а саме більшу кількість доступних черг на один обліковий запис, відправку Push-повідомлень про стан черги більшій частині клієнтів, Push-зв’язок з минулими клієнтами та можливість зайняти клієнтів під час очікування чимось корисним, наприклад заповненням електронної анкети, сплатою послуг або принаймні ознайомленням з інформацією за посиланням."])},
      "buyPremiumAcnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придбати"])},
      "guide": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб зайняти чергу клієнту потрібно відсканувати відповідний (перший) QR-код, перейти за посиланням і погодитись."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нижченаведеної таблиці відображаються записи усіх клієнтів, відповідно до їх черги. Клієнти, що вже були обслуговані, позначені іншим кольором."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Існують три способи зафіксувати початок обслуговування чергового клієнта:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Сканування клієнтом другого QR-коду. Cпрацює лише для клієнта на горі черги."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Кнопка «Почати обслуговування» у додатку клієнта. Доступно, якщо це дозволено налаштуваннями, і лише клієнту на горі черги."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Кнопка «Наступний» у нижченаведеної таблиці, коли черга вільна. При цьому необхідно переконатися, що до вас підійшов саме клієнт з відповідним номером."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У разі відповідного налаштування, черга переводиться у стан «Зайнято» на весь час роботи з клієнтом, інакше вона завжди вільна."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти зі стану «Зайнято» та зафіксувати кінець обслуговування можливо або кнопкою «Наступний» посеред таблиці, або кнопкою «Вільно» у шапці."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кнопка «Перерва» дозволяє тимчасово блокувати початок обслуговування будь-яким способом."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо налаштування дозволяють, ви можете змінювати позицію клієнтів у черзі, обслуговувати їх позачергово або видаляти."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Також ви можете блокувати додавання у чергу нових людей."])}
      ]
    },
    "statement": {
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обслуговування поточного клієнта."])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вільно. Можливо обслуговування."])},
      "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимчасова перерва."])},
      "serviceAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо вручну встановити поточний стан черги «Зайнято». Він вмикається автоматично на початку обслуговування наступного клієнта. Оберіть, кого саме ви бажаєте прийняти."])},
      "short": {
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зайнято"])},
        "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вільно"])},
        "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерва"])}
      },
      "closed": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис у чергу дозволено"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис у чергу припинено"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис у чергу припинено, згідно розкладу"])}
      ]
    },
    "dialogs": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добре"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "confirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження дії"])},
      "point_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати обслуговування клієнта"])},
      "point_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершити обслуговування клієнта та викликати наступного"])},
      "point_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зсунути клієнта у черзі на одну позицію вниз"])},
      "point_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти цього клієнта поза чергою"])},
      "point_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити клієнта з черги"])},
      "point_kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити клієнта зі списку"])},
      "point_recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати клієнту push-повідомлення"])},
      "point_dragndrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити місце клієнта у черзі"])},
      "point_tail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемістити клієнта у кінець черги"])},
      "point_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нового віртуального учасника черги (без приладу)"])},
      "qrComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати обслуговування"])},
      "qrQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стати у чергу"])},
      "saveSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування"])},
      "userMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення"])},
      "bw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строгий"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чудний"])}
    },
    "setup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва черги (не перекладається)"])},
      "allow_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатковий адміністративний аккаунт"])},
      "allow_admins_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дає можливість іншим людям безпечно керувати чергою без можливості зміни налаштувань"])},
      "allow_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнтам дозволено поступатися чергою"])},
      "allow_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовуйте, наприклад, коли важливо зберегти місце у черзі для клієнтів, які запізнюються"])},
      "allow_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливе обслуговування поза чергою"])},
      "allow_urgent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовуйте, коли клієнти обслуговуються не завжди за чергою, наприклад при очікувані замовлення у ресторані самообслуговування"])},
      "auto_busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично переводити чергу у стан «Зайнято»"])},
      "auto_busy_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан черги стає «Зайнято» кожен раз, коли клієнт починає обслуговування, незалежно від того, хто це ініціював. Використовуйте цю функцію, якщо обслуговування клієнта потребує часу. У іншому випадку черга завжди буде у стані «Вільно»"])},
      "urgent_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функція кнопки «Urgent»"])},
      "urgent_button_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опція «Підняти на гору черги» лише змінює позицію клієнта у черзі. Почати обслуговування, якщо така дія передбачена, у цьому випадку потрібно вручну."])},
      "urgent_button_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати позачергове обслуговування"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підняти на гору черги"])}
      ],
      "allow_recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформування клієнтів, яких вже обслужили"])},
      "allow_recall_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дає можливість повідомити клієнта, наприклад, про те, що він дещо залишив"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформувати про стан черги"])},
      "push_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коли ви натискаєте кнопку «Вільно», клієнт на горі черги отримує запрошення. Інші клієнти у черзі будуть повідомлені про її стан. Корисно, якщо обслуговування відбувається суворо за чергою"])},
      "push_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрошувати на обслуговування"])},
      "push_complete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнт отримує запрошення, коли ви натискаєте кнопку обслуговування на його позиції у черзі"])},
      "push_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фраза запрошення"])},
      "push_text_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрана вами фраза надсилається клієнту обраною їм мовою"])},
      "push_text_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підходьте, будь ласка"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше замовлення готове"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пора починати"])}
      ],
      "push_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для кількості клієнтів на горі черги"])},
      "push_qty_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі інші клієнти у черзі не отримують повідомлення"])},
      "start_idx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початковий номер обслуговування"])},
      "restart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновляти нумерацію при порожній черзі"])},
      "restart_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновляти нумерацію на початку дня при порожній черзі"])},
      "keep_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігати чергу на наступний день"])},
      "keep_daily_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ця опція неактивна, усі необслуговані учора клієнти видаляються"])},
      "save_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не видаляти обслугованих учора клієнтів"])},
      "save_daily_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед початком нового дня усі клієнти, що були обслуговані напередодні, видаляються. Ви можете заблокувати цю операцію, якщо бажаєте їх зберігати"])},
      "refresh_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час щоденного оновлення черги"])},
      "refresh_time_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За замовчуванням черга оновлюється у період протягом 15 хвилин після 04:00 за місцевим часом. Якщо у цей час ви працюєте, оберіть довільне значення початку періоду"])},
      "refresh_time_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22:00"])}
      ],
      "userdataAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропонувати клієнтам заповнити форму"])},
      "userdataAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дає можливість клієнту використати час очікування для повідомлення важливої інформації і тим самим прискорити обслуговування"])},
      "userdata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на Google Forms або інший сервіс"])},
      "userdata_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я параметру для відправки ідентифікатора клієнта"])},
      "userdata_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це необхідно, щоб ідентифікувати клієнта у заповненій їм формі"])},
      "userdata_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати токен у якості ідентифікатора клієнта"])},
      "userdata_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За замовчуванням у якості ідентифікатора клієнта використовується його номер у черзі. Використання унікального 6-символьного токену замість цього буде корисним, якщо вам потрібна абсолютно унікальна ідентифікація, а нумерація клієнтів у черзі періодично оновлюється"])},
      "userdata_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки клієнтів ігнорувати"])},
      "userdata_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цій кількості клієнтів на горі черги не буде пропонуватися заповнення форми, оскільки на це немає часу"])},
      "userpayAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропонувати клієнтам онлайн оплату"])},
      "userpayAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дає можливість клієнту використати час очікування для оплати послуг і тим самим прискорити обслуговування."])},
      "userpay_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на платіжну сторінку"])},
      "userpay_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я параметру для відправки ідентифікатора клієнта"])},
      "userpay_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це необхідно, щоб ідентифікувати клієнта у платежу"])},
      "userpay_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати токен у якості ідентифікатора клієнта"])},
      "userpay_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За замовчуванням у якості ідентифікатора клієнта використовується його номер у черзі. Використання унікального 6-символьного токену замість цього буде корисним, якщо вам потрібна абсолютно унікальна ідентифікація, а нумерація клієнтів у черзі періодично оновлюється"])},
      "userpay_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки клієнтів ігнорувати"])},
      "userpay_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цій кількості клієнтів на горі черги не буде пропонуватися онлайн оплата, оскільки на це немає часу"])},
      "userinfoAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропонувати клієнтам посилання з інформацією"])},
      "userinfoAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дає можливість клієнту використати час очікування для ознайомлення з важливою інформацією і тим самим скоротити кількість питань при обслуговуванні"])},
      "userinfo_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на інформаційний ресурс"])},
      "userinfo_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необов'язкове ім'я параметру - ідентифікатора клієнта"])},
      "userinfo_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це корисно, наприклад, щоб ідентифікувати клієнта у посиланні на сторінку"])},
      "userinfo_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати токен у якості ідентифікатора клієнта"])},
      "userinfo_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За замовчуванням у якості ідентифікатора клієнта використовується його номер у черзі. Використання унікального 6-символьного токену замість цього буде корисним, якщо вам потрібна абсолютно унікальна ідентифікація, а нумерація клієнтів у черзі періодично оновлюється"])},
      "userinfo_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки клієнтів ігнорувати"])},
      "userinfo_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цій кількості клієнтів на горі черги не буде пропонуватися ознайомлення з інформацією, оскільки на це немає часу"])},
      "show_idx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер обслуговування"])},
      "show_idx_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей номер надається клієнту при постановці у чергу та зберігається, навіть якщо клієнти поступаються чергою. Кожен клієнт окрім цього ідентифікується унікальним токеном з 6 символів. Токен не повідомляється клієнту"])},
      "show_shedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розклад роботи"])},
      "show_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан черги перед клієнтом"])},
      "show_before_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Небажано відображати це, якщо передбачене обслуговування поза чергою"])},
      "show_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стан черги позаду клієнта"])},
      "show_after_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Небажано відображати це, якщо передбачене обслуговування поза чергою"])},
      "show_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середній час обслуговування"])},
      "show_average_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обчислюється за даними обслуговування попередніх клієнтів. Використовуйте, якщо це реально надасть можливість клієнту оцінити час очікування"])},
      "show_selfcomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кнопка «Почати обслуговування»"])},
      "show_selfcomplete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця кнопка дозволяє клієнту самостійно ініціювати початок обслуговування, коли настане його черга. Інакше це можливо лише шляхом сканування QR-коду або через панель адміністратора."])},
      "premiumInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблоковані налаштування доступні у платній версії"])},
      "push_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправка push-повідомлень"])},
      "order_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила черги"])},
      "idx_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нумерація у черзі"])},
      "userdata_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма з даними про клієнта"])},
      "info_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація для клієнтів"])},
      "show_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про чергу у додатку користувача"])},
      "userpay_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн оплата"])},
      "resolve_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось не гаразд у налаштуваннях. Виправте помилки, будь ласка"])},
      "cleanup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увага! Зміна налаштувань при наявності клієнтів у черзі може призвести до неочікуваних результатів. Радимо виконувати налаштування при порожній черзі."])}
    },
    "validation": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необхідно вказати"])},
      "numberValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно число в діапазоні"])},
      "lengthValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необхідна фраза із кількістю символів"])},
      "timeInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінець періоду має бути пізніше початку"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібний вірний e-Mail"])}
    },
    "shedule": {
      "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати учасника вручну"])},
      "inQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У черзі"])},
      "inQueueFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У черзі з"])},
      "serviceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обслуговується з"])},
      "serviceFromT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийнятий о"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікування"])},
      "queueFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з"])},
      "inQueueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
      "serviceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обслуговування"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані збережено"])},
      "virtual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вірт."])},
      "week": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пн"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ср"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сб"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нд"])}
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати елемент розкладу"])},
      "titleAdd": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати робочий день"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати перерву"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати період запису"])}
      ],
      "killWorkday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити цей запис з розкладу"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінець"])},
      "typeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип елементу розкладу"])},
      "mustWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно обрати хоча б один з днів тижня."])},
      "zeroWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає вільних днів тижня для створення такого елементу розкладу."])},
      "dailyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнтам довзолено ставати у чергу в робочий час."])},
      "addAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У разі додавання або видалення запису про робочий час усі записи про перерви на ці дні тижня будуть видалені для запобігання несумісностей"])},
      "type": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий час"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерва"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період запису у чергу"])}
      ],
      "typeInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно вказати початок та кінець робочого дня для обраних днів тижня. Для кожного дня тижня може бути лише один робочий день."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно вказати початок та кінець перерви для обраних днів тижня. Перерв може бути кілька протягом робочого часу."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно вказати початок та кінець періоду, коли клієнтам дозволено ставати у чергу у обрані дні тижня. Таких періодів може бути кілька протягом кожного дня тижня."])}
      ]
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезавантажити"])},
      "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка зв'язку. Будь ласка, спробуйте ще. Якщо ви впевнені, що проблем зі зв'язком немає, можете видалити всі дані додатку, а потім відновити свій акаунт. Налаштування та стан черги втрачені не будуть."])},
      "noQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такої черги не існує."])},
      "autorisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка авторизації адміністратора. Потрібна повторна реєстрація."])},
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо створити такий обліковий запис."])},
      "queueCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо створити таку чергу."])},
      "pointComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей клієнт вже обслуговувався."])},
      "pointNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей клієнт ще не обслуговувався."])},
      "pointSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей клієнт не може поступитися чергою."])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо зараз прийняти цього клієнта."])},
      "sheduleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний тип елементу розкладу."])},
      "sheduleData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірна структура даних розкладу."])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний запит або структура даних."])},
      "dnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо змінити позицію клієнта у черзі."])},
      "sheduleWeekdayDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий день на один або кілька вказаних вами днів тижня вже існує."])},
      "sheduleTimeCrossingBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Існуючі перерви перехрещуються з новою."])},
      "sheduleTimeCrossingBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Існуючі періоди відкриття черги перехрещуються з новим."])},
      "sheduleTimeNoInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створена перерва виходить поза межі робочого часу."])}
    },
    "patterns": {
      "typeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть шаблон вашої черги"])},
      "typeSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони черги дозволяють вам швидко встановити відповідні налаштування за замовчуванням. Ви маєте можливість змінити ці налаштування будь-коли."])},
      "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гуманітарна допомога"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надання послуг"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресторан самообслуговування"])},
      "simpleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видача гуманітарної допомоги відбувається у порядку живої черги. Щоб стати у чергу, людині потрібно відсканувати QR-код. Коли черга підійде, клієнту для початку обслуговування необхідно відсканувати QR-код або натиснути відповідну кнопку у додатку. Адміністратор також може ініціювати обслуговування. Стан черги завжди «Вільно», оскільки процес обслуговування не займає багато часу. Допускається поступатися чергою та позачергове обслуговування."])},
      "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надання послуг відбувається у порядку живої черги. Щоб стати у чергу, людині потрібно відсканувати QR-код. Коли черга підійде, клієнту для початку обслуговування необхідно відсканувати QR-код або натиснути відповідну кнопку у додатку. Адміністратор також може ініціювати обслуговування. При цьому черга переходить у стан «Зайнято» до закінчення надання послуг. Наступного клієнта буде запрошено, коли стан черги буде «Вільно». Допускається поступатися чергою та позачергове обслуговування."])},
      "restaurantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийом замовлень відбувається у порядку живої черги, а обслуговування – у порядку приготування страв. Щоб стати у чергу, людині потрібно відсканувати QR-код. Коли замовлення буде виконане, клієнт отримає запрошення. Можливість поступатися чергою не передбачена. А обслуговування  фактично відбувається, як позачергове."])}
    }
  }
}