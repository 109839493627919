<template>
  <div>
    <v-fade-transition>
      <v-row class="my-0 py-0" justify="center" v-if="cleanAlert">
        <v-col cols="12">
          <p>{{ $t("message.main.cleanQueueInfo") }}</p>
        </v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-switch
            hide-details
            color="success"
            class="my-0 py-0 q-switch-active"
            v-model="numbersReset"
          >
            <template v-slot:label>
              <span class="q-switch-label text-left text-black">
                {{ $t("message.main.numbersReset") }}
              </span>
            </template>
          </v-switch>
        </v-col>        
        <v-col cols="12" class="my-0 py-0">
          <v-switch
            hide-details
            color="success"
            class="my-0 py-0 q-switch-active"
            v-model="cleanAlertAgree"
          >
            <template v-slot:label>
              <span class="q-switch-label text-left text-black">
                {{ $t("message.main.agree") }}
              </span>
            </template>
          </v-switch>
        </v-col>     
      </v-row>
    </v-fade-transition>
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12" md="6">
        <v-btn
          :disabled="cleanAlert && !cleanAlertAgree"
          color="warning"
          class="w-100"
          elevation="6"
          @click.stop="cleanQueue"
        >
          {{ $t("message.main.cleanQueue") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-fade-transition>
      <v-row class="my-0 py-0" justify="center" v-if="killAlert">
        <v-col cols="12">
          <p>{{ $t("message.main.killQueueInfo") }}</p>
        </v-col>
        <v-col cols="12">
          <v-switch
            hide-details
            color="success"
            class="my-0 py-0 q-switch-active"
            v-model="killAlertAgree"
          >
            <template v-slot:label>
              <span class="q-switch-label text-left text-black">
                {{ $t("message.main.agree") }}
              </span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12" md="6">
        <v-btn
          :disabled="killAlert && !killAlertAgree"
          color="warning"
          class="w-100"
          elevation="6"
          @click.stop="killQueue"
        >
          {{ $t("message.main.killQueue") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12" md="6">
        <v-btn
          color="success"
          class="w-100"
          elevation="6"
          @click.stop="openUrl($t('message.main.areementUrl'))"
        >
          {{ $t("message.main.areementBtn") }}
        </v-btn>
      </v-col>
    </v-row> 
    <v-row class="my-0 py-0" justify="center">
      <v-col cols="12" md="6">
        <v-btn
          color="success"
          class="w-100"
          elevation="6"
          @click.stop="openUrl($t('message.main.privacyUrl'))"
        >
          {{ $t("message.main.privacyBtn") }}
        </v-btn>
      </v-col>
    </v-row>        
  </div>
</template>
<script>
import { DEF } from "../Defaults.js";
export default {
  name: "QOtherSetup",
  data() {
    return {
      cleanAlertAgree: false,
      cleanAlert: false,      
      killAlertAgree: false,
      killAlert: false,
      numbersReset: false,
      def: DEF
    };
  },
  methods: {
    cleanQueue() {
      if (!this.cleanAlert) {
        this.cleanAlert = true;
        this.killAlert = false;
        this.killAlertAgree = false;        
      } else {
        this.$emit("cleanQueue",this.numbersReset);
      }
    },   
    killQueue() {
      if (!this.killAlert) {
        this.killAlert = true;
        this.cleanAlert = false;  
        this.cleanAlertAgree = false;                      
      } else {
        this.$emit("killQueue");
      }
    },
    openUrl(url) {
      window.open(url,'_blank');
    }
  }
};
</script>
<style scoped>
.q-switch-label,
.q-small {
  font-size: 0.9em;
}
</style>
