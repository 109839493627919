<template>
  <v-container style="max-width: 600px">
    <template v-if="config.user">
      <v-row justify="center">
        <v-col cols="12" class="my-0 py-0 mt-4">
          <h2 class="mt-12 text-warning">
            {{$t('message.navbar.account')}}
          </h2>            
          <h3 class="mt-6">
            {{config.title}}
          </h3>          
        </v-col>
        <v-col cols="12" class="my-0 py-0 mt-4">
          <p class="q-small text-left">
            {{$t('message.main.accountInfo')}}
          </p>
        </v-col>         
      </v-row>
      <v-row justify="center" class="mb-4">
        <v-col cols="12" class="my-0 py-0 mt-4">
          <v-select
            :label="$t('message.main.timezoneSelect')"
            variant="solo"
            density="default"
            hide-details
            v-model="timezone"
            class="mb-2"
            :items="timezonesList"
          ></v-select>
        </v-col>   
        <v-col cols="12" class="my-0 py-0 mt-4">
          <p class="q-small text-left">
            {{$t('message.main.timezoneInfo')}}
          </p>
        </v-col>           
      </v-row>
      <v-divider></v-divider>
      <template v-if="config.queues?.length > 0">
        <h3 class="mt-12">
          {{$t('message.navbar.queues')}}
          <template v-if="config.max_queues">
            ({{config.queues?.length}} {{$t('message.navbar.of')}} {{config.max_queues}})
          </template>
        </h3>
        <p v-for="(item, key) in config.queues" :key="key" @click.stop="$emit('select',item.token)" class="my-3 text-center">
          <b>{{item.title}}</b>
        </p>
        <v-divider></v-divider>
      </template>      
      <v-sheet 
        elevation="2" 
        v-if="!config.max_queues || (config.queues?.length < config.max_queues)" 
        class="v-pointer mt-14 mb-4 pt-4 pb-4 w-100 bg-background2 rounded"
        @click.stop="tabFlag = (tabFlag==1) ? 0 : 1"  
      >
        <h3 class="text-center">
          {{$t('message.main.addQueue')}}
        </h3>
      </v-sheet>
      <v-fade-transition>
        <v-row justify="center" v-if="tabFlag == 1">
          <v-col cols="12" class="my-0 py-0 mt-4">
            <v-select
              :label="$t('message.patterns.typeSelect')"
              variant="solo"
              density="default"
              hide-details
              v-model="pattern"
              class="mb-2"
              return-object
              :items="patternsList"
            ></v-select>
          </v-col>
          <v-col cols="12" class="my-0 py-0 mt-4">
            <p v-if="!pattern" class="q-small text-left">
              {{$t('message.patterns.typeSelectInfo')}}
            </p>
            <p v-else class="q-small text-left">
              {{$t(pattern?.description)}}
            </p>        
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 mt-4">
            <v-btn
              class="mx-2 my-3 w-100 bg-success"
              elevation="6"
              :disabled="!pattern"
              @click.stop="addQu"
            >{{$t('message.main.add')}}</v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-sheet 
        v-if="def.premiums" 
        elevation="2" 
        class="v-pointer mt-14 mb-4 pt-4 pb-4 w-100 bg-background2 rounded"
        @click.stop="tabFlag = (tabFlag==3) ? 0 : 3"
      >
        <h3 class="text-center">
          {{$t('message.main.buyPremium')}}
        </h3>
      </v-sheet>
      <v-fade-transition>
        <v-row justify="center" v-if="tabFlag == 3" class="mb-8">
          <v-col cols="12" class="mb-0 py-0 mt-4">
            <p class="q-small text-left">
              {{$t('message.main.buyPremiumInfo')}}
            </p>    
          </v-col>
          <v-col cols="12" sm="6" class="mb-0 py-0 mt-4">
            <v-btn
              class="mx-2 my-3 w-100 bg-success"
              elevation="6"
              @click.stop="buyPremium"
            >{{$t('message.main.buyPremiumAcnt')}}</v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-sheet 
        elevation="2" 
        class="v-pointer mt-14 mb-4 pt-4 pb-4 w-100 bg-background2 rounded"
        @click.stop="tabFlag = (tabFlag==2) ? 0 : 2"
      >
        <h3 class="text-center">
          {{$t('message.main.wipeAcnt')}}
        </h3>
      </v-sheet>
      <v-fade-transition>
        <v-row justify="center" v-if="tabFlag == 2" class="mb-8">
          <v-col cols="12" class="mb-0 py-0 mt-4">
            <p class="q-small text-left">
              {{$t('message.main.wipeAcntInfo')}}
            </p>    
          </v-col>
          <v-col cols="12" sm="6" class="mb-0 py-0 mt-4">
            <v-btn
              class="mx-2 my-3 w-100 bg-success"
              elevation="6"
              @click.stop="$emit('wipe')"
            >{{$t('message.main.wipeAcnt')}}</v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>      
    </template>
    <template v-else>
      <template v-if="config.key">
        <v-row justify="center">
          <v-col cols="12" class="my-0 py-0 mt-4">
            <h3 class="text-center my-6">
              {{$t('message.main.accountCreated')}}
            </h3>
            <p class="q-small text-left">
              {{$t('message.main.accountCreatedInfo')}}
            </p>
          </v-col>
          <v-col cols="12" class="my-0 py-0 mt-4">
            <v-btn
              class="mx-2 my-3 bg-success"
              elevation="6"
              @click.stop="cancelCreation"
            >{{$t('message.main.cancelCreation')}}</v-btn>
          </v-col>           
        </v-row>        
      </template>
      <template v-else>
        <v-sheet elevation="2" class="v-pointer mt-14 mb-4 pt-4 pb-4 w-100 bg-background2 rounded">
          <h3 class="text-center" @click.stop="cleateAccntFlag = !cleateAccntFlag">
            {{$t('message.main.createAccount')}}
          </h3>
        </v-sheet>   
          <v-fade-transition>   
          <v-form v-if="cleateAccntFlag" v-model="regValid" validate-on="blur">      
            <v-row justify="center">
              <v-col cols="12" class="my-0 py-0 mt-4">
                <p class="q-small text-left">
                  {{$t('message.main.createAccountInfo')}}
                </p>
              </v-col>               
              <v-col cols="12" class="my-0 py-0 mt-4">
                <v-text-field
                  type="input"
                  variant="solo"
                  class="mt-2 mb-n2 py-0"  
                  v-model="email"
                  label="eMail"
                  :rules="[validationRules().email]"
                  :placeholder="config.email"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0 mt-4">
                <v-switch 
                  hide-details
                  color="success"
                  class="my-0 py-0 q-switch-active" 
                  v-model="agreementFlag" 
                >
                  <template v-slot:label>
                    <span class="q-switch-label text-left text-black">
                      {{ parseSplit($t('message.main.agreement'),0) }}<br/>
                      <a :href="$t('message.main.areementUrl')" target="_blank">
                        {{ parseSplit($t('message.main.agreement'),1) }}
                      </a>
                      {{ parseSplit($t('message.main.agreement'),2) }}<br/>
                      <a :href="$t('message.main.privacyUrl')" target="_blank">
                        {{ parseSplit($t('message.main.agreement'),3) }}
                      </a><br/>
                      {{ parseSplit($t('message.main.agreement'),4) }}
                    </span>
                  </template>
                </v-switch>          
              </v-col>        
              <v-col cols="12" sm="6" class="my-0 py-0 mt-4">
                <v-btn
                  class="mx-2 my-3 w-100 bg-success"
                  elevation="6"
                  :disabled="!regValid || !agreementFlag"
                  @click.stop="cleateAccnt"
                >{{$t('message.main.createAccount')}}</v-btn>
              </v-col>        
            </v-row>      
          </v-form>        
        </v-fade-transition>        
      </template>
    </template>
    <v-row class="my-16">
    </v-row>    
  </v-container>
</template>
<script>
import QIcon from './QIcon.vue';
import { DEF, QU_PATTERNS, TIME_ZONES } from "../Defaults.js";

export default {
  name: 'QPrifile',
  components: {
    // eslint-disable-next-line
    QIcon
  },
  props: {
    config: {
      type: Object,
      default() {
        return structuredClone(DEF.config);
      }
    }
  },
  data() {
    return {
      def: DEF,
      pattern: null,
      tabFlag: 0,
      cleateAccntFlag: false,
      agreementFlag: false,
      regValid: false,
      email: ''
    };
  },
  computed: {
    timezone: {
      get() {
        return this.config.timezone;
      },
      set(value) {
        this.$emit('timezone', value);
      }
    },
    timezonesList() {
      let list = [];
      TIME_ZONES.forEach( zone => {
        let dta = Intl.DateTimeFormat("en-US", {
            timeStyle: 'long',           
            timeZone: zone
          }).format(new Date()).split(' ');
        list.push({
          title: dta[dta.length-1]+' • '+zone,
          value: zone
        });
      });
      return list;
    },
    patternsList() {
      let patterns = [];
      QU_PATTERNS.forEach(pattern => {
        patterns.push({
          value: pattern.id,
          title: (pattern.premium ? '* ' : '') + this.$t(pattern.title),
          description: pattern.description,
          props: {
            disabled: pattern.premium && !this.config.premium
          }
        });
      });
      return patterns;
    }
  },
  methods: {
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },
    validationRules() {
      return {
        email: value => !!value.match(/^([a-z0-9_\-.])+@([a-z0-9_\-.])+.([a-z0-9])+$/) || this.$t('message.validation.email')        
      };
    },    
    addQu() {
      this.$emit('addQ',this.pattern);
    },
    cleateAccnt() {
      this.$emit('create',this.email);
    },
    cancelCreation() {
      this.$emit('cancel',this.email);
    },
    buyPremium() {
      window.open(DEF.payUrl+'?token='+this.config.user+'&lang='+this.config.locale);
    }
  }
}
</script>
<style scoped>
.q-switch-label, .q-small {
  font-size: 0.9em;
}
</style>